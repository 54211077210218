import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Button, Preloader } from "nlib/ui";
import {
  checkInstitutionAccountsFetching,
  checkInstitutionLinksFetching,
  checkInstitutionsFetching,
  getInstitutionLinksData
} from "selectors/institutions";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ConnectInstitutionWindow from "./lib/ConnectInstitutionWindow";
import Institution from "./lib/Institution";
import InstitutionsActions from "actions/InstitutionsActions";
import NoDataContent from "nlib/common/NoDataContent";
import Page from "nlib/common/Page";
import PageContent from "nlib/common/PageContent";
import PageHeader from "nlib/common/PageHeader";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import UiRoutes from "const/UiRoutes";
import useEnvVars from "hooks/useEnvVars";
import useShowCommonModal from "hooks/useShowCommonModal";
import useShowModal from "hooks/useShowModal";

const { ACCOUNTS } = UiRoutes;

const USER_CANCELLED_SESSION = "UserCancelledSession";

const AddButtonBlock = ({ disabled, onAddAccountButtonClick }) => {
  const { uiTexts } = useSelector(getTextsData);

  return (
    <Button
      primary large
      icon={Icons.Plus}
      disabled={disabled}
      onClick={onAddAccountButtonClick}>
      {uiTexts.connectBankAccounts}
    </Button>
  );
};

const AccountsPage = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [{ ref: reference, error, details }] = useEnvVars();

  const showCommonModal = useShowCommonModal();

  const { uiTexts, messages } = useSelector(getTextsData);

  const fetchingInstitutions = useSelector(checkInstitutionsFetching);

  const fetchingInstitutionAccounts = useSelector(checkInstitutionAccountsFetching);

  const fetchingInstitutionLinks = useSelector(checkInstitutionLinksFetching);

  const selectedBusinessId = useSelector(getSelectedBusinessId);

  const institutionLinksData = useSelector(getInstitutionLinksData);

  const [setupInProcess, setSetupInProcess] = useState(false);

  const fetchingData = fetchingInstitutionLinks || fetchingInstitutionAccounts || fetchingInstitutions;

  const filteredInstitutionLinksData = institutionLinksData.filter((linkData) => linkData.accounts.length);

  const [
    addAccountModalShown,
    showAddAccountModal,
    handleAddAccountModalClose
  ] = useShowModal();

  const handleAddAccountButtonClick = useCallback(async() => {
    const institutionCode = await showAddAccountModal();

    if (institutionCode) {
      const link = await dispatch(InstitutionsActions.getConnectingLink(institutionCode));

      if (link) window.location = link;
    }
  }, [dispatch, showAddAccountModal]);

  useLayoutEffect(() => {
    if (reference) {
      if (error) {
        if (error !== USER_CANCELLED_SESSION) {
          showCommonModal({
            size: "sm",
            text: (details || "").replaceAll("+", " "),
            headerText: uiTexts.error
          });
        }
      } else {
        setSetupInProcess(true);
        dispatch(InstitutionsActions.completeSetup(reference)).then(() => {
          history.replace(`/${selectedBusinessId}${ACCOUNTS}`);
          setSetupInProcess(false);
        });
      }
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }, [details, dispatch, error, history, reference, selectedBusinessId, showCommonModal, uiTexts.error]);

  useEffect(() => {
    dispatch(InstitutionsActions.fetchInstitutionAccounts());
  }, [dispatch]);

  return (
    <Page className={Css.accountsPage}>
      <PageContent>
        <PageHeader>
          {!!filteredInstitutionLinksData.length && (
            <AddButtonBlock
              disabled={fetchingData}
              onAddAccountButtonClick={handleAddAccountButtonClick} />
          )}
        </PageHeader>
        {filteredInstitutionLinksData.length
          ? filteredInstitutionLinksData.map((linkData) => {
            return (
              <Institution
                key={linkData.id}
                disabled={fetchingData}
                linkData={linkData} />
            );
          })
          : (
            <div className={Css.noDataContent}>
              {(fetchingData || setupInProcess) ? <Preloader /> : (
                <NoDataContent
                  description={messages.bankConnectionCreatingGreeting}>
                  <AddButtonBlock
                    disabled={fetchingData}
                    onAddAccountButtonClick={handleAddAccountButtonClick} />
                </NoDataContent>
              )}
            </div>
          )}
        {addAccountModalShown && (
          <ConnectInstitutionWindow onClose={handleAddAccountModalClose} />
        )}
      </PageContent>
    </Page>
  );
};

export default React.memo(AccountsPage);
