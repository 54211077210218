import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { AutoTooltip, Button } from "nlib/ui";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import Card from "nlib/common/Card";
import Constants from "const/Constants";
import InstitutionLinkStatuses from "const/InstitutionLinkStatuses";
import InstitutionsActions from "actions/InstitutionsActions";
import React, { useCallback } from "react";
import Table, { TableCell, TableHead, TableRow } from "nlib/ui/Table";
import Utils from "utils/Utils";
import classNames from "classnames";
import moment from "moment";
import useShowCommonModal from "hooks/useShowCommonModal";

const { ACCOUNT_EXPIRATION_DAYS_WARNING } = Constants;

const StatusText = ({ expiresAt, expired, disconnected, expiresSoon }) => {
  const { uiTexts } = useSelector(getTextsData);

  if (expired) {
    return <span className={classNames(Css.text, Css.expired)}>{uiTexts.expired}</span>;
  }
  if (disconnected) {
    return <span className={classNames(Css.text, Css.expired)}>{uiTexts.disconnected}</span>;
  }

  return (
    <>
      <span className={classNames(Css.text, expiresSoon && Css.warning)}>
        {`${uiTexts.expires}: ${moment.duration(moment.utc(expiresAt).diff(moment.utc())).humanize(true)}`}
      </span>
      {!expiresSoon && <Icons.CheckCircle className={Css.successIcon} />}
    </>
  );
};

const Institution = ({ disabled, linkData }) => {
  const dispatch = useDispatch();

  const showCommonModal = useShowCommonModal();

  const { institution, accounts, status, expiresAt } = linkData;

  const expired = moment.utc(expiresAt).isSameOrBefore(moment.utc());

  const disconnected = status !== InstitutionLinkStatuses.ONLINE;

  const { code: institutionCode } = institution;

  const { uiTexts, messages } = useSelector(getTextsData);

  const expiresSoon = !expired && !disconnected
    && moment.duration(moment.utc(expiresAt).diff(moment.utc())).asDays() < ACCOUNT_EXPIRATION_DAYS_WARNING;

  const handleReconnectAccount = useCallback(async() => {
    const result = await showCommonModal({
      text: messages.actionConfirm,
      confirm: true
    });

    if (result) {
      const link = await dispatch(InstitutionsActions.getConnectingLink(institutionCode));

      if (link) window.location = link;
    }
  }, [institutionCode, dispatch, messages, showCommonModal]);

  const handleTableAccountDelete = useCallback(async(event) => {
    event.stopPropagation();

    const { currentTarget: { dataset: { id } } } = event;

    const result = await showCommonModal({
      text: messages.actionConfirm,
      confirm: true
    });

    if (result) {
      await dispatch(InstitutionsActions.deleteInstitutionAccount(id));
    }
  }, [dispatch, messages.actionConfirm, showCommonModal]);

  return (
    <Card className={Css.institution}>
      <div className={Css.cardBody}>
        <div className={Css.header}>
          <div className={Css.logo}>
            <img src={institution.logo} alt={institution.name} />
          </div>
          <div className={Css.name}>{institution.name}</div>
          <div className={Css.expiresAt}>
            <StatusText
              expiresAt={expiresAt}
              expiresSoon={expiresSoon}
              expired={expired}
              disconnected={disconnected} />
            {(expiresSoon || expired || disconnected) && (
              <Button
                danger={expired || disconnected}
                warning={!expired && !disconnected}
                title={expired ? uiTexts.reconnect : uiTexts.renew}
                disabled={disabled}
                className={Css.reconnectButton}
                onClick={handleReconnectAccount}>
                <Icons.ArrowsClockwise />
              </Button>
            )}
          </div>
        </div>
        <Table theme="flat" className={Css.table}>
          <TableRow>
            <TableHead className={Css.nameCell}>{uiTexts.accountName}</TableHead>
            <TableHead className={Css.balanceCell}>{uiTexts.balance}</TableHead>
            <TableHead className={Css.transactionsCell}>{uiTexts.transactions}</TableHead>
            <TableHead className={Css.lastUpdatedAtCell}>{uiTexts.lastTransaction}</TableHead>
            <TableHead className={Css.lastUpdatedAtCell}>{uiTexts.lastSync}</TableHead>
            <TableHead className={Css.actionsCell} />
          </TableRow>
          {accounts.map((accountData) => {
            const {
              id: accountId,
              name,
              balance,
              currency,
              lastUpdatedAt,
              lastSyncAt,
              transactionsCount
            } = accountData;

            const balanceText = Utils.toMoneyString(balance || 0, currency);

            return (
              <TableRow key={accountId}>
                <TableCell className={Css.nameCell}>{name}</TableCell>
                <TableCell className={Css.balanceCell}>
                  <AutoTooltip
                    active={!!balance}
                    className={balance ? CommonCss.coloredNumber : ""}
                    data-negative={balance < 0 ? "" : undefined}>
                    {balanceText}
                  </AutoTooltip>
                </TableCell>
                <TableCell className={Css.transactionsCell}>
                  <Icons.Repeat />
                  <span>{transactionsCount}</span>
                </TableCell>
                <TableCell className={Css.lastUpdatedAtCell}>
                  {lastUpdatedAt
                    ? moment.utc(lastUpdatedAt).format(Constants.DATETIME_FORMATS.DATE_TEXT)
                    : Constants.EMPTY_PLACEHOLDER}
                </TableCell>
                <TableCell className={Css.lastUpdatedAtCell}>
                  {lastSyncAt
                    ? moment.duration(moment.utc(lastSyncAt).diff(moment.utc())).humanize(true)
                    : (
                      <>
                        <Icons.Spinner data-wait />
                        <span>{uiTexts.inProgress}</span>
                      </>
                    )}
                </TableCell>
                <TableCell className={Css.actionsCell}>
                  <Button
                    light
                    title={uiTexts.delete}
                    disabled={disabled}
                    className={Css.deleteButton}
                    data-id={accountId}
                    onClick={handleTableAccountDelete}>
                    <Icons.Trash />
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </div>
    </Card>
  );
};

export default React.memo(Institution);
